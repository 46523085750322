<script setup lang="ts">
import {Head, Link, useForm, usePage} from '@inertiajs/inertia-vue3';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import {translate} from "../../../../ts/helpers/translationHelper";
import {Company} from "../../../../ts/models/Company";
import {computed} from "vue";
import Primary2Button from "@/Components/Primary2Button.vue";

const props = defineProps({
    company: Company
});

const page = usePage();

const textColor = computed(() => page.props.value.textColor);
const bgColor = computed(() => page.props.value.backgroundColor);
const secondaryColor = computed(() => page.props.value.secondaryColor);
const primaryColor = computed(() => page.props.value.primaryColor);

const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    terms: false,
});

const updateChecked = (checkboxData) => form.terms =  checkboxData.newVal;

const submit = () => {
    form.post(route('auth.register'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<template>
    <Head :title="translate('auth.register')"/>

    <form @submit.prevent="submit">
        <div>
            <InputLabel for="firstname" :value="translate('auth.firstname')" />
            <TextInput
                id="name"
                v-model="form.first_name"
                type="text"
                class="mt-1 block w-full"
                required
                autofocus
                autocomplete="name"
            />
            <InputError class="mt-2" :message="form.errors.first_name" />
        </div>
        <div>
            <InputLabel for="lastname" :value="translate('auth.lastname')" />
            <TextInput
                id="name"
                v-model="form.last_name"
                type="text"
                class="mt-1 block w-full"
                required
                autocomplete="name"
            />
            <InputError class="mt-2" :message="form.errors.last_name" />
        </div>

        <div class="mt-4">
            <InputLabel for="email" :value="translate('auth.email')" />
            <TextInput
                id="email"
                v-model="form.email"
                type="email"
                class="mt-1 block w-full"
                required
            />
            <InputError class="mt-2" :message="form.errors.email" />
        </div>

        <div class="mt-4">
            <InputLabel for="password" :value="translate('auth.password_label')" />
            <TextInput
                id="password"
                v-model="form.password"
                type="password"
                class="mt-1 block w-full"
                required
                autocomplete="new-password"
            />
            <InputError class="mt-2" :message="form.errors.password" />
	        <InputLabel>
		        <i class="text-xs italic mt-2" v-html="translate('forms.passwordTerms')"></i>
	        </InputLabel>

        </div>

        <div class="mt-4">
            <InputLabel for="password_confirmation" :value="translate('auth.repeatPassword')" />
            <TextInput
                id="password_confirmation"
                v-model="form.password_confirmation"
                type="password"
                class="mt-1 block w-full"
                required
                autocomplete="new-password"
            />
            <InputError class="mt-2" :message="form.errors.password_confirmation" />
        </div>

        <div class="mt-4 mb-8">
            <InputLabel for="terms ">
                <div class="flex items-center">
                    <Checkbox id="terms" :checked="form.terms ? 1 : 0" @update:checked="updateChecked" name="terms" required />

                    <div :style="'color:'+textColor" class="ml-2" v-html="translate('auth.agreePrivacy', [
												{key: ':route', value: route('company.terms')},
												{key: ':route_privacy', value: route('company.privacy')}
												])">
                    </div>
                </div>
                <InputError class="mt-2" :message="form.errors.terms" />
            </InputLabel>
        </div>

        <Primary2Button class="w-full text-primaryText" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
            {{ translate('auth.register_ask') }}
        </Primary2Button>
        <div class="flex items-center justify-center mt-4">
            <span :style="'color:'+textColor" class="text-sm" v-html="translate('auth.haveAccount', [{key: ':route', value: route('login')}])">
            </span>
        </div>
    </form>

</template>
